body { background-color: #ffffff; }
body { color: var(--clr-897); }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
font-family: 'Nunito Sans';
font-weight: 700;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 25px;

@media #{$medium-up} {
font-size: 40px;

}
}
h2 {
font-family: 'Noto Sans';
font-weight: 700;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 22px;

@media #{$medium-up} {
font-size: 35px;

}
}
h3 {
font-family: 'Noto Sans';
font-weight: 700;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 30px;

}
}
h4 {
font-family: 'Noto Sans';
font-weight: 700;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 22px;

}
}
h5 {
font-family: 'Noto Sans';
font-weight: 700;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
h6 {
font-family: 'Grechen Fuemen';
font-weight: normal;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
@media #{$large-up} {
font-size: 50px;

}
}
.button {
font-family: 'Noto Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-body, .me-HtmlText blockquote {
font-family: 'Arapey';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 22px;

}
}
.me-Quote .quote-author {
font-family: 'Noto Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

}
ol,ul {
font-family: 'Noto Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
font-family: 'Noto Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 22px;

@media #{$medium-up} {
font-size: 24px;

}
@media #{$large-up} {
font-size: 26px;

}
}
summary {
font-family: 'Noto Sans';
font-weight: 700;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 25px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Noto Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 14px;

}
nav.mainmenu > .menu-item > div > a {
font-family: 'Noto Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 14px;

}
nav.secondarymenu > .menu-item > a {
font-family: 'Noto Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Noto Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 20px;

}
body {
font-family: 'Nunito Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

}
p { padding-bottom: 1em; }
a {color: var(--clr-897);}
a:hover {color: var(--clr-898);}
/* Primary:2 */
.MES2 {
background-color: var(--clr-897);
color: #ffffff;
 }
/* Primary:3 */
.MES3 {
background-color: var(--clr-897);
color: #ffffff;
 }
.MES3 {
background-color: var(--clr-897);
color: #ffffff;
h1.MEC3, h2.MEC3, h3.MEC3, h4.MEC3, h5.MEC3, h6.MEC3 { color: #ffffff;
 }
 }
cite.MEC3{
color: #ffffff;
}
/* Secondary:4 */
.MES4 {
background-color: var(--clr-899);
color: #ffffff;
&:hover { color: #ffffff;}
border-radius: 12px;
padding: 10px 30px;

border-width: 1px;
border-style: solid;
border-color: transparent;
 }
/* Secondary:5 */
.MES5 {
background-color: var(--clr-899);
color: #ffffff;
 }
.MES5 {
background-color: var(--clr-899);
color: #ffffff;
h1.MEC5, h2.MEC5, h3.MEC5, h4.MEC5, h5.MEC5, h6.MEC5 { color: #ffffff;
 }
 }
cite.MEC5{
color: #ffffff;
}
/* Alternate:6 */
.MES6 {
background-color: var(--clr-900);
 }
/* Alternate:7 */
.MES7 {
background-color: var(--clr-900);
 }
/* Alternate:8 */
.MES8 {
background-color: var(--clr-900);
 }
.MES8 {
background-color: var(--clr-900);
 }
/* Alternate:9 */
.MES9 {
background-color: var(--clr-900);
 }
.MES9 {
background-color: var(--clr-900);
 }
/* Light:10 */
.MES10 {
background-color: var(--clr-901);
 }
/* Light:11 */
.MES11 {
background-color: var(--clr-901);
 }
.MES11 {
background-color: var(--clr-901);
 }
/* Shade 1:12 */
.MES12 {
background-color: var(--clr-902);
color: #ffffff;
 }
/* Shade 1:13 */
.MES13 {
background-color: var(--clr-902);
color: #ffffff;
 }
.MES13 {
background-color: var(--clr-902);
color: #ffffff;
h1.MEC13, h2.MEC13, h3.MEC13, h4.MEC13, h5.MEC13, h6.MEC13 { color: #ffffff;
 }
 }
cite.MEC13{
color: #ffffff;
}
/* Copyright panel:14 */
.MES14 {
font-size: 12.8px;
 }
.MES14 {
font-size: 12.8px;
 }
cite.MEC14{
font-size: 12.8px;
}
/* News Grid:16 */
.MES16 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-900);}
padding: 15px;

@media #{$large-up} {
padding: 40px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-901);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES16 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-900);}
padding: 15px;

@media #{$large-up} {
padding: 40px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-901);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
/* Main Menu:17 */
nav.me-Menu.MES17 {
& .menu-item.MEC17, & .menu-item.MEC17 > div.MEC17{ & > a.MEC17{color: #ffffff;
text-transform: uppercase;
}
 &:hover > a.MEC17{color: var(--clr-898);
}
 }
&.horizontal > .menu-item.MEC17 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC17 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC17 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC17 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 15px;}

& .sub-menu .menu-item a{padding: 15px 20px;}




.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Paypal button:18 */
.MES18 {
background-color: var(--clr-903);
color: #ffffff;
 }
/* Home slider:19 */
.MES19 {
& .slider-arrow {color: var(--clr-904);
font-size: 100px;
@media #{$medium-up} {
font-size: 100px;
};
@media #{$large-up} {
font-size: 80px;
};
}& .slick-dots {text-align: center;
 button {margin: 0 1px;
;border-color: 16;
border-radius: 50%;
border-style:solid;
border-width:2px;
@media #{$medium-up} {
border-width: 2px;
};
@media #{$large-up} {
border-width: 2px;};
&:hover{border-color: 11;}
background-color: 2;
background-clip: padding-box;
&:hover {background-color: 11;}
width:3px;
height:3px;
@media #{$medium-up} {
width:3px;
height:3px;
};
@media #{$large-up} {
width:3px;
height:3px;
};
&:hover{background-color: 11;}
}}
 }
/* Responsive Menu:20 */
nav.responsive-menu {
.menu-item.MEC20{background-color: var(--clr-901);}
& .menu-item.MEC20, & .menu-item.MEC20 > div.menu-item-wrap{ & > a.MEC20, & > i{color: var(--clr-897);
text-transform: uppercase;
text-align: center;
}
  }
& .menu-item.MEC20 { border:0;

 border-bottom-width: 1px; }
& .menu-item > .menu-item-wrap {padding: 0;}

& .sub-menu{ .sub-menu {}}

 }
/* login buttons:21 */
.MES21 {
background-color: transparent;
color: #ffffff;
border-radius: 50px;
@media #{$medium-up} {
padding: 0;

}
border-width: 1px;
border-style: solid;
border-color: transparent;
 }
/* Copyright panel grey:22 */
.MES22 {
background-color: var(--clr-901);
font-size: 12.8px;
 }
.MES22 {
background-color: var(--clr-901);
font-size: 12.8px;
 }
cite.MEC22{
font-size: 12.8px;
}
/* carousel padding:23 */
.MES23 {
padding: 10px;

border-width: 1px;
border-style: solid;
border-color: var(--clr-900);
 }
.MES23 {
padding: 10px;

border-width: 1px;
border-style: solid;
border-color: var(--clr-900);
 }
/* Carousel grey pad:24 */
.MES24 {
background-color: #f2f2f2;
padding: 20px 15px;

@media #{$large-up} {
padding: 20px;

}
 }
.MES24 {
background-color: #f2f2f2;
padding: 20px 15px;

@media #{$large-up} {
padding: 20px;

}
 }
/* border line:25 */
.MES25 {
& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: var(--clr-897) transparent transparent transparent;}
& > hr {border-top-style: solid;}
 }
/* Top Strip Panel:26 */
.MES26 {
background-color: #ffffff;
color: var(--clr-897);
font-size: 16px;
@media #{$large-up} {
font-size: 12.8px;
};
padding: 5px;

border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent var(--clr-901) transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES26 {
background-color: #ffffff;
color: var(--clr-897);
font-size: 16px;
@media #{$large-up} {
font-size: 12.8px;
};
padding: 5px;

border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent var(--clr-901) transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
h1.MEC26, h2.MEC26, h3.MEC26, h4.MEC26, h5.MEC26, h6.MEC26 { color: var(--clr-897);
 }
h1.MEC26 { @media #{$medium-up} { font-size: 32px; }; }
h1.MEC26 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC26 { @media #{$medium-up} { font-size: 28px; }; }
h2.MEC26 { @media #{$large-up} { font-size: 35px; }; }
h3.MEC26 { @media #{$medium-up} { font-size: 24px; }; }
h3.MEC26 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC26 { @media #{$medium-up} { font-size: 17.6px; }; }
h4.MEC26 { @media #{$large-up} { font-size: 22px; }; }
h5.MEC26 { @media #{$medium-up} { font-size: 14.4px; }; }
h5.MEC26 { @media #{$large-up} { font-size: 18px; }; }
h6.MEC26 { @media #{$medium-up} { font-size: 14.4px; }; }
h6.MEC26 { @media #{$large-up} { font-size: 50px; }; }
 }
a.MEC26 { color: var(--clr-897);
&:hover { color: var(--clr-898);}
 }
cite.MEC26{
color: var(--clr-897);
font-size: 16px;
@media #{$large-up} {
font-size: 12.8px;
};
}
/* White BG Transparent:27 */
.MES27 {
background-color: var(--clr-904);
 }
.MES27 {
background-color: var(--clr-904);
 }
/* Popup form Window:28 */
.MES28 {
background-color: #ffffff;
padding: 20px;

border-width: 5px;
border-style: solid;
border-color: var(--clr-901);
 }
.MES28 {
background-color: #ffffff;
padding: 20px;

border-width: 5px;
border-style: solid;
border-color: var(--clr-901);
 }
/* Exhibition rollover:29 */
.MES29 {
background-color: #ffffff;
&:hover, &.hover { background-color: var(--clr-900);}
 }
.MES29 {
background-color: #ffffff;
&:hover, &.hover { background-color: var(--clr-900);}
 }
/* White text:30 */
.MES30 {
color: #ffffff;
 }
.MES30 {
color: #ffffff;
 }
a.MEC30 { color: #ffffff;
 }
cite.MEC30{
color: #ffffff;
}
/* Image Back grpund:31 */
.MES31 {
background-color: var(--clr-897);
& > .underlay, & > .inner-overlay { opacity: 0.5 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://demo.udo.net.au/img/747/197');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
background-attachment: fixed;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
min-height:350px;
@media #{$medium-up} {
min-height: 500px;};
@media #{$large-up} {
min-height: 600px;};
 }
.MES31 {
background-color: var(--clr-897);
& > .underlay, & > .inner-overlay { opacity: 0.5 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://demo.udo.net.au/img/747/197');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
background-attachment: fixed;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
min-height:350px;
@media #{$medium-up} {
min-height: 500px;};
@media #{$large-up} {
min-height: 600px;};
h1.MEC31, h2.MEC31, h3.MEC31, h4.MEC31, h5.MEC31, h6.MEC31 { color: #ffffff;
 }
 }
a.MEC31 { color: #ffffff;
&:hover { color: var(--clr-898);}
 }
cite.MEC31{
color: #ffffff;
}
/* White bg:32 */
.MES32 {
background-color: #ffffff;
color: var(--clr-897);
 }
.MES32 {
background-color: #ffffff;
color: var(--clr-897);
h1.MEC32, h2.MEC32, h3.MEC32, h4.MEC32, h5.MEC32, h6.MEC32 { color: var(--clr-897);
 }
 }
a.MEC32 { color: var(--clr-899);
 }
.MEC32 li {color: var(--clr-897-flat);}
cite.MEC32{
color: var(--clr-897);
}
/* Page Accordion:33 */
details.MES33 {
 }
/* Panel broder grey:35 */
.MES35 {
border-width: 1px;
border-style: solid;
border-color: var(--clr-900);
 }
.MES35 {
border-width: 1px;
border-style: solid;
border-color: var(--clr-900);
 }
/* Black button:36 */
.MES36 {
background-color: #000000;
&:hover {background-color: #a9a9a9;}
color: #ffffff;
&:hover { color: #ffffff;}
border-radius: 12px;
padding: 10px 30px;

border-width: 1px;
border-style: solid;
border-color: transparent;
 }
